import {visit} from 'unist-util-visit';

function ColumnPlugin() {
    function isColumnNode(node) {
        return (node.children.length > 0 && node.children[0].value === '#col');
    }

    function replaceNode(node, index, parent) {
        if (!isColumnNode(node)) {
            return;
        }

        let columns = 0;
        parent.children.forEach((value) => isColumnNode(value) ? ++columns : 0);

        let nodeIndex = 0;
        let rowNode = null;
        let columnNode = null;

        while (nodeIndex < parent.children.length) {
            const cn = parent.children[nodeIndex];

            if (isColumnNode(cn)) {
                if (rowNode === null) {
                    rowNode = {
                      type: 'bootstrapRow',
                      children: []
                    };

                    parent.children[nodeIndex++] = rowNode;
                } else {
                    parent.children.splice(nodeIndex, 1);
                }

                columnNode = {
                    type: 'bootstrapColumn',
                    columns: columns,
                    children: []
                };

                rowNode.children.push(columnNode);
                continue;
            }

            if (columnNode === null) {
                continue;
            }

            columnNode.children.push(cn);
            parent.children.splice(nodeIndex, 1);
        }
    }

    return function transformer(tree) {
        visit(tree, 'paragraph', replaceNode);
    };
}

export default ColumnPlugin;