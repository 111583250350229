import React from 'react';
import {Col} from 'react-bootstrap';

class KeyStat extends React.Component {
    render() {
        return (
            <Col md={3} sm={6} xs={12}>
                <div className='text-center mx-auto mt-3' style={{ width: '150px'}}>
                    <div className='text-white py-4 bg-primary rounded-top force-background-color'>
                        <div className='my-0 h2'>{this.props.stat}</div>
                    </div>
                    <div className='border-primary border py-2 rounded-bottom'>
                        <div className='m-0 h6'>{this.props.name}</div>
                    </div>
                </div>
            </Col>
        );
    }
}

export default KeyStat;