import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class IconInfo extends React.Component {
    render() {
        return (
            <div>
                <FontAwesomeIcon icon={this.props.icon} className='text-primary mr-2 d-lg-none' width='20px'/>
                <a href={this.props.link} className='text-dark tap-text' target='_blank' rel='noreferrer'>
                    {this.props.text}
                </a>
                <FontAwesomeIcon icon={this.props.icon} className='text-primary ml-2 d-none d-lg-inline' width='20px'/>
            </div>
        );
    }
}

export default IconInfo;