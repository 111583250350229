import React from 'react';
import CV from './CV';
import {
    Container,
    Nav,
    Navbar,
} from 'react-bootstrap';
import logo from './logo.svg';

class App extends React.Component {
  render() {
    return (
        <div>
            <Navbar bg='dark' variant='dark' sticky='top'>
                <Container fluid='xl'>
                    <Navbar.Brand className='pt-0 d-none d-sm-block'>
                        <img
                            alt=''
                            src={logo}
                            width='30'
                            height='30'
                            className='d-inline-block align-top'
                        />
                        { ' Sergej Grivcov\'s CV' }
                    </Navbar.Brand>

                    <Navbar.Brand className='pt-0 d-block d-sm-none'>
                        <img
                            alt=''
                            src={logo}
                            width='30'
                            height='30'
                            className='d-inline-block align-top'
                        />
                        { ' Sergej Grivcov' }
                    </Navbar.Brand>

                    <Nav className='mr-0'>
                        <Nav.Item>
                            <a className='btn btn-warning' href='/sergej-grivcov-cv.pdf' target='_blank' rel='noreferrer'>
                                PDF
                            </a>
                        </Nav.Item>
                    </Nav>
                </Container>
            </Navbar>
            <div className='mb-4 d-none d-print-none d-lg-block'/>
            <div>
                <CV/>
            </div>
            <div className='mb-5 d-print-none'/>
        </div>
    );
  }
}

export default App;
