import React from 'react';
import {Row} from 'react-bootstrap';

function BootstrapRowRenderer({node, inline, className, children}) {
    return (
        <Row>
            { children }
        </Row>
    );
}

export default BootstrapRowRenderer;