import {visit} from 'unist-util-visit';

function PageBreakPlugin() {
    function replaceNode(node, index, parent) {
        const regex = /^#pagebreak$/m;

        if (!regex.test(node.children[0].value)) {
            return;
        }

        node.type = 'pageBreak';
        node.children = [];
    }

    return function transformer(tree) {
        visit(tree, 'paragraph', replaceNode);
    };
}

export default PageBreakPlugin;