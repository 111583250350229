import {visit} from 'unist-util-visit';

function KeyStatPlugin() {
    let toFlatten = [];

    function flattenParent(node) {
        let current = 0;
        let lastRow = null;

        while (current < node.children.length) {
            if (node.children[current].type !== 'keyStat') {
                lastRow = null;
                ++current;
                continue;
            }

            if (!lastRow) {
                lastRow = {
                    type: 'bootstrapRow',
                    children: [node.children[current]]
                };

                node.children[current++] = lastRow;
                continue;
            }

            lastRow.children.push(node.children[current]);
            node.children.splice(current, 1);
        }
    }

    function replaceNode(node, index, parent) {
        const regex = /^keystat:$/m;

        if (!regex.test(node.value)) {
            return;
        }

        const stats = node.value.split("\n");

        if (stats.length < 3) {
            return;
        }

        node.type = 'keyStat';
        node.statName = stats[1].trim();
        node.statValue = stats[2].trim();

        toFlatten.push(parent);
    }

    return function transformer(tree) {
        toFlatten = [];
        visit(tree, 'code', replaceNode);

        for (const flattenNode of toFlatten) {
            flattenParent(flattenNode);
        }
    };
}

export default KeyStatPlugin;