import React from 'react';
import Section from '../components/Section';
import SectionSmall from '../components/SectionSmall';

function SectionRenderer({node, inline, className, children}) {
    if (node.depth > 1) {
        return (
            <SectionSmall name={ node.sectionName } jobTitle={ node.jobTitle } dateStart={ node.dateStart } dateEnd={ node.dateEnd }>
                { children }
            </SectionSmall>
        );
    }

    return (
        <Section name={ node.sectionName }>
            { children }
        </Section>
    );
}

export default SectionRenderer;