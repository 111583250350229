import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import IconInfo from './components/IconInfo';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import MdContent from './components/MdContent';
import Picture from './sergej.webp';
import cvContent from './assets/cv.md';

class CV extends React.Component {
    render() {
        return (
            <Container>
                <Row className='align-items-center'>
                    <Col lg={2}>
                        <div className='square-container'>
                            <img src={ Picture } alt='Sergej' width='100%' height='100%'/>
                        </div>
                    </Col>
                    <Col lg={7} className='mt-4 mt-lg-0'>
                        <h1 className='text-primary font-weight-bold'>Sergej Grivcov</h1>
                        <div className='h4 d-none d-xl-block'>Senior Director of Software Development at GoDaddy</div>
                        <div className='h5 d-xl-none'>Senior Director of Software Development at GoDaddy</div>
                    </Col>
                    <Col lg={3} className='text-lg-right mt-2 mt-lg-0'>
                        <IconInfo
                            text='Belgrade, Serbia'
                            link='https://goo.gl/maps/qFx6JqbkdJtd7aE98'
                            icon={faLocationDot}
                        />
                        <IconInfo
                            text='linkedin.com/in/zrgisa'
                            link='https://www.linkedin.com/in/zrgisa/'
                            icon={faLinkedinIn}
                        />
                        <IconInfo
                            text='zrgirox@gmail.com'
                            link='mailto:zrgirox@gmail.com'
                            icon={faEnvelope}
                        />
                        <IconInfo
                            text='+381637537596'
                            link='tel:+381637537596'
                            icon={faPhone}
                        />
                    </Col>
                </Row>

                <MdContent src={ cvContent }/>
            </Container>
        );
    }
}

export default CV;