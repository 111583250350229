import React from 'react';
import {Spinner} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import SectionPlugin from '../markdown/SectionPlugin';
import SectionRenderer from '../markdown/SectionRenderer';
import KeyStatPlugin from '../markdown/KeyStatPlugin';
import KeyStatRenderer from '../markdown/KeyStatRenderer';
import BootstrapRowRenderer from '../markdown/BootstrapRowRenderer';
import ColumnPlugin from '../markdown/ColumnPlugin';
import BootstrapColumnRenderer from '../markdown/BootstrapColumnRenderer';
import PageBreakPlugin from '../markdown/PageBreakPlugin';
import PageBreakRenderer from '../markdown/PageBreakRenderer';

class MdContent extends React.Component {
    constructor() {
        super();
        this.state = {
            text: '',
            loading: true
        };
    }

    componentDidMount() {
        const self = this;

        if (!self.props.src) {
            self.setState({
                text: self.props.text,
                loading: false
            });
            return;
        }

        fetch(self.props.src)
            .then(response => response.text())
            .then(function (text) {
                self.setState({
                    text: text,
                    loading: false
                });
            });
    }

    render() {
        return (
            <div>
                {
                    this.state.loading ?
                        <Spinner animation='border' variant='primary' /> :
                        <ReactMarkdown
                            plugins={[PageBreakPlugin, SectionPlugin, KeyStatPlugin, ColumnPlugin]}
                            renderers={{
                                'section': SectionRenderer,
                                'keyStat': KeyStatRenderer,
                                'bootstrapRow': BootstrapRowRenderer,
                                'bootstrapColumn': BootstrapColumnRenderer,
                                'pageBreak': PageBreakRenderer,
                            }}
                        >
                            { this.state.text }
                        </ReactMarkdown>
                }
            </div>
        );
    }
}

export default MdContent;