import React from 'react';
import {Col} from 'react-bootstrap';

function BootstrapColumnRenderer({node, inline, className, children}) {
    const columnMap = {
        1: (
            <Col xs={12} className='col-layout'>
                { children }
            </Col>
        ),
        2: (
            <Col lg={6} sm={12} className='col-layout'>
                { children }
            </Col>
        ),
        3: (
            <Col lg={4} sm={6} className='col-layout'>
                { children }
            </Col>
        ),
    }

    return (columnMap[!node.columns ? 1 : node.columns]);
}

export default BootstrapColumnRenderer;