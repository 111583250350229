function SectionPlugin() {
    return function transformer(tree) {
        let current = 0;
        let lastHeading = null;

        while (current < tree.children.length) {
            if (tree.children[current].type === 'heading' && tree.children[current].depth < 3) {
                lastHeading = tree.children[current];

                const namePieces = lastHeading.children[0].value.split('|');

                lastHeading.type = 'section';
                lastHeading.sectionName = namePieces[0].trim();
                lastHeading.jobTitle = namePieces.length > 1 ? namePieces[1].trim() : '';
                lastHeading.dateStart = namePieces.length > 2 ? namePieces[2].trim() : '';
                lastHeading.dateEnd = namePieces.length > 3 ? namePieces[3].trim() : 'Present';
                lastHeading.children = [];

                ++current;
                continue;
            }

            if (lastHeading == null || tree.children[current].type === 'pageBreak') {
                ++current;
                continue;
            }

            lastHeading.children.push(tree.children[current]);
            tree.children.splice(current, 1);
        }
    };
}

export default SectionPlugin;