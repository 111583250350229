import React from 'react';
import {Col, Row} from 'react-bootstrap';

class Section extends React.Component {
    render() {
        return (
            <Row className='mt-4 print-avoid-break'>
                <Col lg={2} className='text-primary text-lg-right text-uppercase font-weight-bold mt-1'>
                    {this.props.name}
                </Col>
                <Col lg={10} className='border-top border-dark mt-2 mt-lg-3 pt-2 pt-lg-4'>
                    {this.props.children}
                </Col>
            </Row>
        );
    }
}

export default Section;