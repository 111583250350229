import React from 'react';
import {Col, Row} from 'react-bootstrap';

class SectionSmall extends React.Component {
    render() {
        return (
            <Row className='mt-4 print-avoid-break'>
                <Col lg={2} className='text-lg-right'>
                    { this.props.name ? (
                        <>
                            <div className='h5 text-primary d-inline'>{this.props.name}</div>
                        </>
                    ) : null }
                    { this.props.dateStart ? (
                        <>
                            <div className='d-none d-lg-block'>{this.props.dateStart} - </div>
                            <div className='d-none d-lg-block'>{this.props.dateEnd}</div>
                        </>
                    ) : null }
                </Col>
                <Col lg={10}>
                    <div className='mb-2'>
                        {
                            this.props.jobTitle ? <div className='h5 mb-0'>{this.props.jobTitle}</div> : null
                        }
                        { this.props.dateStart ? (
                            <>
                                <div className='d-inline d-lg-none font-italic'>({this.props.dateStart} - {this.props.dateEnd})</div>
                            </>
                        ) : null }
                    </div>
                    {this.props.children}
                </Col>
            </Row>
        );
    }
}

export default SectionSmall;